import { createRouter,  createWebHistory,createWebHashHistory} from 'vue-router'
import Index from '../views/Index.vue'
import {env} from "vue-meta-info/.eslintrc";

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    meta: { title: 'Home'},
  },
  {
    path: '/:article_id',
    name: 'show',
    component: () => import('../views/Show.vue'),
    meta: { title: 'Show'},
  },
  {
    path: '/page/:page_num',
    name: 'page',
    component: () => import('../views/Page.vue'),
    meta: { title: 'Show Page'},
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/Search.vue'),
    meta: { title: 'Search'},
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/User.vue'),
    meta: { title: 'User'},
  }
]

const router = createRouter({
  //mode: 'history',
  //history: createWebHashHistory(process.env.BASE_URL),//#本地调试模式
  linkActiveClass: 'router-active',
  history:createWebHistory("/"), //服务器模式
  //base: '/',
  base: process.env.VUE_APP_SRC,
  routes
})

//动态修改页面title
// router.beforeEach((to, from, next) => {
//   window.document.title = to.meta.title
//   next()
// })

export default router
