<template>
    <header>
        <div class="headTop">
            <div class="headTop_left">
                 <a class="headTop_logo" href="/"><img src="@/assets/images/logo.png" alt=""></a>
                <p class="headTop_p">Fresh discoveries everyday</p>
            </div> 
            <div class="headTop_right">
            </div>
        </div>
        <!-- <div class="headTool">
            <p class="headTool_title">AIverse</p>
            <a class="headTool_title_search" href="#/search">
            </a>
        </div> -->
    </header>
</template>
<script>
import {ref} from "vue"
//jquery.js
import $ from 'jquery'
	export default{
        name:'Header',
		setup(){
            const country = ref('US'); //国家名称
            const classico = ref('icon-us'); //国旗
            const langFun = (className,countryName)=> {
                classico.value = className;
                country.value = countryName;
            }
			
			return{
                country,
                classico,
                langFun
	
			}
		},
		data(){
            return{
                countryid:9, //国家ID

            }
		},
		props:[],
		created() {

		},
		methods:{
            headToolMenu(){
                $('.wap_nav_layer').addClass('on');
                $('.wap_nav').addClass('on');
            },
            headToolClose(){
                $('.wap_nav_layer').removeClass('on');
                $('.wap_nav').removeClass('on');
            },
            //国家下拉
            langselect(){
                if($('.lang_select_gj').is(':hidden')){
                    $('.lang_select_gj').show();
                }else{
                    $('.lang_select_gj').hide();
                }
            },
            

		}
	
	}
</script>