<template>
  <div class="appdiv feed">
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Noto Sans,Noto Sans JP,Noto Sans CJK JP,-apple-system,BlinkMacSystemFont,Helvetica Neue,Segoe UI,Hiragino Kaku Gothic ProN,Hiragino Sans,ヒラギノ角ゴ ProN W3,Arial,メイリオ,Meiryo,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.appdiv{
  background-color: #fff;
  margin: 0 auto;
  max-width: 700PX;
  padding-top: 75px;
}
.appdiv.feed{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
}

</style>
