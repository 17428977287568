import request from '@/utils/request'
//商品相关服务，主要是选品

var host_name = ""
if (process.env.NODE_ENV=="development") {
    host_name = "https://restapp.kekexp.com/"
} else {
    host_name = "https://restapp.kekexp.com/"
}


//获得商品文章
export function search_user_goods(params) {
    return request({
        url: host_name + 'article/search_user_goods',
        method: 'get',
        params
    })
}

//获得商品文章详情
export function get_goods_info(params) {
    return request({
        url: host_name + 'article/get_goods_info',
        method: 'get',
        params
    })
}

//获得商品相关文章
export function get_relate_article(params) {
    return request({
        url: host_name + 'article/get_relate_article',
        method: 'get',
        params
    })
}