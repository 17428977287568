<template>
  <!--头部-->
  <Header></Header>
  <!--内容区-->
  <div class="main">
    <div class="main_index">
        <!-- <div class="list_nav">
          <div class="list_nav_m">
            <dd class="on">
              <a href="">For You</a>
            </dd>
            <dd>
              <a href="">Fashion</a>
            </dd>
            <dd>
              <a href="">Beauty</a>
            </dd>
            <dd>
              <a href="">Food</a>
            </dd>
            <dd>
              <a href="">Wellness</a>
            </dd>
            <dd>
              <a href="">Travel</a>
            </dd>
            <dd>
              <a href="#/index">Home</a>
            </dd>
          </div>
        </div> -->
      <div class="listdata" ref="wrapper">
        <ul>
          <li v-for="(article,articleIndex) in table_data" :key="articleIndex">
<!--            <a href="javascript:void(0)" @click="show_article( article.article_id )">-->
              <a :href='"/"+article.article_id+".html"'>
              <div class="listdata_bka">
                <div class="listdata_bka_img"  style="height: 200px;overflow: hidden;display: flex;align-items: center;">
                  <img :src="article.img" alt="">
                </div>
                <div class="listdata_info">
                  <div class="listdata_info_title">
                      {{decodeURIComponent(article.title)}}
                  </div>
                  <div class="listdata_info_p">
                      {{decodeURIComponent(article.content)}}
                  </div>
                </div>
              </div>
              <div class="listdata_bkb">
                <div class="listdata_bkb_user">
                    <img :src="article.avatar" alt="">
                    <p>{{article.nickname}} </p>
                </div>
                <div class="listdata_bkb_partition"></div>
                <div class="listdata_bkb_browse">
                  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 9.371C2 5.703 4.574 3 7.955 3c1.7 0 3.08.754 4.042 1.929C12.963 3.752 14.331 3 16.036 3 19.415 3 22 5.701 22 9.371c0 2.18-.922 4.284-2.47 6.24-1.545 1.955-3.745 3.802-6.386 5.488l-.002.001-.403-.632.408.63h-.003c-.151.099-.33.192-.508.263a1.791 1.791 0 01-.64.139c-.245 0-.476-.074-.64-.14a3.27 3.27 0 01-.5-.255l-.008-.005c-2.642-1.687-4.84-3.534-6.384-5.49C2.92 13.655 2 11.55 2 9.372zM7.955 4.5C5.485 4.5 3.5 6.446 3.5 9.371c0 1.741.734 3.527 2.141 5.31 1.408 1.783 3.46 3.523 6.01 5.152.085.053.18.101.262.134a.782.782 0 00.083.029 1.47 1.47 0 00.335-.157l.004-.003c2.553-1.63 4.609-3.372 6.02-5.155 1.41-1.784 2.145-3.57 2.145-5.31 0-2.923-1.993-4.871-4.464-4.871-1.5 0-2.65.8-3.384 2.13a.75.75 0 01-1.316-.004C10.624 5.312 9.457 4.5 7.955 4.5z" fill="currentColor"></path></svg>
                  <p>{{article.like_num}} Likes</p>
                </div>
              </div>
            </a>
          </li>
        </ul>

      </div>
      <div class="load">
          <a :href='"/page/"+next_page_num'>
             ~Load more~
          </a>
      </div>
    </div>
  </div>
</template>

<script>
//公共文件 头部/底部
import Header from "@/components/public/Header.vue";
import { search_user_goods } from '@/api/article'
import Bscroll from "better-scroll";
import { encodeEmojiToUtf8mb4, decodeEmojiFromUtf8mb4 } from './emoji-utils';
export default {
  name: 'Index',
  components: {
    Header
  },
    metaInfo() {
        return {
            title: "AI Verse",
            meta: [
                {
                    name: "keywords",
                    content: "AIverse,lemon8,fashion"
                },
                {
                    name: "description",
                    content: "lemon8 by AI author:)"
                }
            ]
        };
    },
  setup(){
			return{

			}
		},
		data(){
        return {
          table_data:[],//列表数据
            start:0,// 请求开始位置
            limit:10,// 请求结束位置
            page_num:1,//第一页
            next_page_num:2,
            current_index:0,//当前记录位置
            is_loading:false,//加载状态
            scroll:null,//上拉
            content:''
        }
		},
		props:['active'],
		created() {
        this.search_user_goods()
        window.addEventListener("scroll", this.scrollBottom);
		},
    onMounted() {
    },


		methods:{
        //获得用户选品
        search_user_goods() {
            search_user_goods({start:this.start,limit:this.limit}).then(response => {
                var data_list = response["results"] //获得数据
                this.table_data.push(...data_list) //数组添加到table后面
            })
        },
        scrollBottom() {
        //加载更多
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        //变量windowHeight是可视区的高度
        var windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        //变量scrollHeight是滚动条的总高度
        var scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        //滚动条到底部的条件
        if (scrollTop + windowHeight == scrollHeight && scrollTop > 100) {
          //写后台加载数据的函数
          this.start += 10;
          this.next_page_num += 1;
          search_user_goods({
            start:this.start,
            limit:this.limit
          }).then(response => {
                var data_list = response["results"] //获得数据
                //this.table_data = []
                this.table_data.push(...data_list) //数组添加到table后面
                // console.log(this.table_data) //测试
            })
        }
    },
        show_article(article_id) {
            console.log(article_id)
            //this.$router.push({path:'/show', query:{goods_id:goods_id}})
            this.$router.push({
                path:"/"+article_id + ".html",
            })
        },
		}
}
</script>
